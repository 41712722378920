import { LWeb3 } from '../libs/LWeb3'
import { ABI_NFT } from '../contracts/NFT'
import { ABI_NFTStaking } from '../contracts/NFTStaking'

class NFTStaking {

    constructor(address, tokenAddress) {
        this.approved = false
        this.address = address
        this.tokenAddress = tokenAddress
        this.depositToken = {
            symbol: 'NFT'
        }
        this.nftsInWallet = []
        this.bnbAddress = "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
    }

    debugErrorString(_text) {
		return 'Transaction failed at: ' + _text		
	}

    getContract() {
        let web3 = LWeb3.findWeb3Connection('user')?.web3
        if(web3 === null || web3 === undefined) {
            return null
        }
        let con = new web3.eth.Contract(ABI_NFTStaking, this.address)
        return con
    }

    getTokenContract() {
        let web3 = LWeb3.findWeb3Connection('user')?.web3
        if(web3 === null || web3 === undefined) {
            return null
        }
        let con = new web3.eth.Contract(ABI_NFT, this.tokenAddress)
        return con
    }

    async approve() {
        let token = this.getTokenContract()
        if(token === null) {
            return
        }
        await window.chef.trySend(
            token.methods.setApprovalForAll(this.address, true),
            window.chef.account,
            this.debugErrorString("approve"),
            undefined
        )
    }

    async isApproved() {
        let token = this.getTokenContract()
        if(token === null) {
            return false
        }
        let res = await token.methods.isApprovedForAll(window.chef.account, this.address).call()
        return res
    }

    async balanceOf() {
        let token = this.getTokenContract()
        if(token === null) {
            return
        }
        let balance = await token.methods.balanceOf(window.chef.account).call()
        return balance
    }

    async getNFTsInWallet() {
        let token = this.getTokenContract()
        if(token === null) {
            return
        }
        let tokenIds = []
        let balance = await token.methods.balanceOf(window.chef.account).call()
        for(let i = 0; i < parseInt(balance); i ++) {
            let tokenId = await token.methods.tokenOfOwnerByIndex(window.chef.account, i).call()
            tokenIds.push(tokenId)
        }
        return tokenIds
    }

    async getNFTsInContract() {
        let contract = this.getContract()
        if(contract === null) {
            return
        }
        let tokenIds = []
        return tokenIds
    }

    async getInfo() {
        let contract = this.getContract()
        if(contract === null) {
            return null
        }
        
        this.tokenInstanceForPrice = window.chef.findToken(this.bnbAddress)
        let price = await contract.methods.NFTValue().call()
        let apr = await contract.methods.APR().call()
        let tvl = this.tokenInstanceForPrice.getPriceUSDForAmount(await contract.methods.TotalShares().call())
        let staked_balance = this.tokenInstanceForPrice.getPriceUSDForAmount(parseFloat(await contract.methods.stakedValue(window.chef.account).call()) * price)
        let balance = await this.balanceOf()
        let approved = await this.isApproved()
        this.nftsInWallet = await this.getNFTsInWallet()

        tvl = LWeb3.smartFormatFiat(tvl, window.chef.stableToken)
        // balance = LWeb3.smartFormatFiat(balance, window.chef.stableToken)
        staked_balance = LWeb3.smartFormatFiat(staked_balance, window.chef.stableToken)

        return {
            apr,
            tvl: parseFloat(parseFloat(tvl) > 0 ? parseFloat(tvl).toFixed(3) : tvl),
            balance: balance,
            staked_balance: parseFloat(parseFloat(staked_balance) ? parseFloat(staked_balance).toFixed(3) : staked_balance),
            approved
        }
    }

    async claim() {
        let contract = this.getContract()
        if(contract === null) {
            return
        }
        await window.chef.trySend(
            contract.methods.WithdrawDividents(),
            window.chef.account,
            this.debugErrorString("claim"),
            undefined
        )
        document.dispatchEvent(new CustomEvent("updateState", {
            detail: {}
        }));
    }

    async stake(id) {
        let contract = this.getContract()
        if(contract === null) {
            return
        }
        await window.chef.trySend(
            contract.methods.Deposit(id),
            window.chef.account,
            this.debugErrorString("stake"),
            undefined
        )
        document.dispatchEvent(new CustomEvent("updateState", {
            detail: {}
        }));
    }

    async statkeAll() {
        let contract = this.getContract()
        if(contract === null) {
            return
        }
        await window.chef.trySend(
            contract.methods.DepositAll(),
            window.chef.account,
            this.debugErrorString("stakeAll"),
            undefined
        )
        document.dispatchEvent(new CustomEvent("updateState", {
            detail: {}
        }));
    }

    async unstake(id) {
        let contract = this.getContract()
        if(contract === null) {
            return
        }
        await window.chef.trySend(
            contract.methods.WithdrawNFT(id),
            window.chef.account,
            this.debugErrorString("withdraw"),
            undefined
        )
        document.dispatchEvent(new CustomEvent("updateState", {
            detail: {}
        }));
    }

    async unstakeAll() {
        let contract = this.getContract()
        if(contract === null) {
            return
        }
        await window.chef.trySend(
            contract.methods.WithdrawAll(),
            window.chef.account,
            this.debugErrorString("withdrawAll"),
            undefined
        )
        document.dispatchEvent(new CustomEvent("updateState", {
            detail: {}
        }));
    }
}

export default NFTStaking