//template & style
import Template_MoonVault from './components/Templates/Template_MoonVault';
import './styles/Style_Nevada.css';

//config
const config = 
{
	contract: {
		vegasStakingAddress: '0x5A9A05779579E1b4B02b20Ed3226Cedb9F7F56EE',
		nftStakingAddress  : '0xC8ec4cB526E05D278e7128265035a22776aB3F5F',
		vegasTokenAddress  : '0xE6884e29ffE5C6F68F4958cf201B0e308f982AC9',
		nftTokenAddress    : '0xCAD59546014762c0093Ad704d95e1fadBDEC7793'
	},
	//////////////////////////////////////////
	//page info
	page:
	{
		name: "VEGAS",
		defaultChain: 56,
		nativeToken: "0x7Aa653eAF70589722CBCa728802D05ceBc3583bF"
	},
	
	//////////////////////////////////////////
	//settings
	settings:
	{
		alwaysUseUserWeb3: true
	},

	//////////////////////////////////////////
	//template info
	template:
	{
		useMenuGlow: false,
		showNativePrice: true
	},

    //////////////////////////////////////////
	//web 3 connections
    web3Data:
	{
		connections: [ ],		
		multiCalls:
		[
			{
				chain: 56,
				multiCall: '0xB94858b0bB5437498F5453A16039337e5Fdc269C'	
			}
		],
		web3RPCs:
		[
			{
				chain: 56,
				multiCall: '0xB94858b0bB5437498F5453A16039337e5Fdc269C',
				nodes:
				[
					'https://bsc-dataseed.binance.org',
					'https://bsc-dataseed1.defibit.io/',
					'https://bsc-dataseed1.ninicoin.io/'
				]			
			}
		]
	}
    //////////////////////////////////////////
}

//export
export
{
	config,

	Template_MoonVault as Template	
}