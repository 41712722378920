import { LWeb3 } from '../libs/LWeb3'
import { ABI_IToken } from '../contracts/IToken'
import { ABI_VegasStaking } from '../contracts/VegasStaking'
import { constants } from 'ethers'

class VegasStaking {

    constructor(address, tokenAddress) {
        this.approved = false
        this.address = address
        this.tokenAddress = tokenAddress
        this.depositToken = {
            symbol: 'Vegasino'
        }
    }

    debugErrorString(_text) {
		return 'Transaction failed at: ' + _text		
	}

    getContract() {
        let web3 = LWeb3.findWeb3Connection('user')?.web3
        if(web3 === null || web3 === undefined) {
            return null
        }
        let con = new web3.eth.Contract(ABI_VegasStaking, this.address)
        return con
    }

    getTokenContract() {
        let web3 = LWeb3.findWeb3Connection('user')?.web3
        if(web3 === null || web3 === undefined) {
            return null
        }
        let con = new web3.eth.Contract(ABI_IToken, this.tokenAddress)
        return con
    }

    async approve() {
        let token = this.getTokenContract()
        if(token === null) {
            return
        }
        await window.chef.trySend(
            token.methods.approve(this.address, constants.MaxUint256),
            window.chef.account,
            this.debugErrorString("approve"),
            undefined
        ).then(() => {
            this.approved = true
        })
    }

    async balanceOf() {
        let token = this.getTokenContract()
        if(token === null) {
            return
        }
        let balance = await token.methods.balanceOf(window.chef.account).call()
        return balance
    }

    async getInfo() {
        let contract = this.getContract()
        if(contract === null) {
            return null
        }

        this.tokenInstanceForPrice = window.chef.findToken(this.tokenAddress)
        let apr = await contract.methods.APR().call()
        let tvl = this.tokenInstanceForPrice.getPriceUSDForAmount(await contract.methods.TotalShares().call())
        let staked_balance_wei = await contract.methods.stakedTokens(window.chef.account).call()
        let staked_balance = this.tokenInstanceForPrice.getPriceUSDForAmount(await contract.methods.stakedTokens(window.chef.account).call())
        let roi = 0
        let balance = this.tokenInstanceForPrice.getPriceUSDForAmount(await this.balanceOf())

        tvl = LWeb3.smartFormatFiat(tvl, window.chef.stableToken)
        balance = LWeb3.smartFormatFiat(balance, window.chef.stableToken)
        staked_balance = LWeb3.smartFormatFiat(staked_balance, window.chef.stableToken)

        return {
            roi,
            apr,
            tvl: parseFloat(parseFloat(tvl) > 0 ? parseFloat(tvl).toFixed(3) : tvl),
            balance: parseFloat(parseFloat(balance) > 0 ? parseFloat(balance).toFixed(3) : balance),
            staked_balance: parseFloat(parseFloat(staked_balance) ? parseFloat(staked_balance).toFixed(3) : staked_balance),
            staked_balance_wei
        }
    }

    async claim() {
        let contract = this.getContract()
        if(contract === null) {
            return
        }
        await window.chef.trySend(
            contract.methods.WithdrawDividents(),
            window.chef.account,
            this.debugErrorString("claim"),
            undefined
        )
        document.dispatchEvent(new CustomEvent("updateState", {
            detail: {}
        }));
    }

    async compound() {
        let contract = this.getContract()
        if(contract === null) {
            return
        }
        await window.chef.trySend(
            contract.methods.Compound(),
            window.chef.account,
            this.debugErrorString("compound"),
            undefined
        )
        document.dispatchEvent(new CustomEvent("updateState", {
            detail: {}
        }));
    }

    async stake(amount) {
        let contract = this.getContract()
        if(contract === null) {
            return
        }
        let web3 = LWeb3.findWeb3Connection('user')?.web3
        // alert(web3.utils.toWei(amount.toString()))
        await window.chef.trySend(
            contract.methods.Deposit(web3.utils.toWei(amount.toString())),
            window.chef.account,
            this.debugErrorString("deposit"),
            undefined
        )
        document.dispatchEvent(new CustomEvent("updateState", {
            detail: {}
        }));
    }

    async unstake(amount) {
        let contract = this.getContract()
        if(contract === null) {
            return
        }
        
        let web3 = LWeb3.findWeb3Connection('user')?.web3
        await window.chef.trySend(
            contract.methods.WithdrawDeposit(web3.utils.toWei(amount.toString())),
            window.chef.account,
            this.debugErrorString("withdraw"),
            undefined
        )
        document.dispatchEvent(new CustomEvent("updateState", {
            detail: {}
        }));
    }

    async unstakeAll() {
        let contract = this.getContract()
        if(contract === null) {
            return
        }
        await window.chef.trySend(
            contract.methods.WithdrawAll(),
            window.chef.account,
            this.debugErrorString("withdrawAll"),
            undefined
        )
        document.dispatchEvent(new CustomEvent("updateState", {
            detail: {}
        }));
    }
}

export default VegasStaking